<template>
    <div id="page-radius-session-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading.session" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'radius-sessions' }">Back to Radius Sessions</router-link>

                    <div class="header-info">
                        <h1 class="heading">Radius Session Details</h1>
                    </div>
                </div>
            </div>

            <div class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.details"
                        :data="session_details"

                        title="Details"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
    },

    data() {
        return {
            session: null,

            rows: {
                details: [
                    { key: 'uudi',          title: 'UUID'         },
                    { key: 'service_uuid',  title: 'Service UUID' },
                    { key: 'service_name',  title: 'Service Name' },
                    { key: 'stack',         title: 'Stack'        },
                    { key: 'customer_name', title: 'Username'     },
                    { key: 'nas',           title: 'NAS'          },
                    { key: 'connected',     title: 'Connected'    },
                    { key: 'disconnected',  title: 'Disconnected' },
                    { key: 'ip4',           title: 'IPv4 Address' },
                    { key: 'ip6',           title: 'IPv6 Address' },
                ],
            },

            loading: {
                session: false,
            },

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadSessionDetails()
        },
        
        loadSessionDetails() {
            this.loading.session = true

            this.$store.dispatch('getRadiusSessionByUUID', { uuid: this.session_uuid }).then(session => {
                this.session = session

                this.loading.session = false
            }).catch(error => {
                console.log('Error getRadiusSessionByUUID', this.session_uuid)
                console.dir(error)

                if (this.on_sidebar) {
                    this.$emit('close-sidebar')
                } else {
                    this.$router.push({ name: 'radius-sessions', replace: true })
                }
            })
        },
    },

    watch: {
        session_uuid() {
            this.loadSessionDetails()
        },
    },

    computed: {
        session_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        session_details() {
            return this.session ? {
                uudi:          this.session.UUID,
                service_uuid:  this.session.ServiceUUID,
                service_name:  this.session.ServiceName,
                stack:         this.session.Stack,
                customer_name: this.session.CustomerName,
                nas:           this.session.NAS,
                connected:     this.session.ConnectedAtNanos ? formatDateNano(this.session.ConnectedAtNanos) : '?',
                disconnected:  this.session.DisconnectedAtNanos ? formatDateNano(this.session.DisconnectedAtNanos) : '-',
                ip4:           this.session.IPv4,
                ip6:           this.session.IPv6,
            } : {}
        },
    },
}
</script>

<style lang="scss">
#page-radius-session-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }
}

@media (max-width: $tablet-size) {
    #page-radius-session-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-radius-session-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            .col-mob-12 {
                margin-top: 15px;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>