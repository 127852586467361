<template>
    <app-dialog-info
        class="app-dialog-code"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 v-if="title" class="heading">{{ title }}</h2>
        </template>

        <template #body>
            <app-code :code="code" />
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 1410 },

        code: { required: true },
        title: { type: String, default: null },
    },

    components: {
        appDialogInfo,
        appCode: () => import('@/components/app-code'),
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
// .app-dialog.app-dialog-info.app-dialog-code {
//     .app-dialog-body {}
// }

@media (max-width: $mobile-size) {
    // .app-dialog.app-dialog-info.app-dialog-code {
    //     .app-dialog-body {}
    // }
}
</style>