<template>
    <div class="app-code" :class="{ collapsed }">
        <pre>
            <span class="brace">[</span>
                {{ code }}
            <span class="brace">]</span>
        </pre>

        <button v-if="collapsed" class="btn btn-table-action action-secondary action-more btn-show-dialog" @click="showDialog"></button>

        <app-dialog-code
            v-if="show_dialog"
            v-model="show_dialog"

            :code="code"
            :title="title"
        />
    </div>
</template>

<script>
import appDialogCode from './components/app-dialog-code'

export default {
    props: {
        code: { required: true },
        collapsed: { type: Boolean, default: false },
        title: { type: String, default: null },
    },

    components: {
        appDialogCode,
    },

    data() {
        return {
            show_dialog: false,
        }
    },

    methods: {
        showDialog() {
            this.show_dialog = true
        },
    },
}
</script>

<style lang="scss">
.app-code {
    min-width: 0;

    padding: 12px 24px;

    background-color: #ebebeb;

    border: solid 1px #666;
    border-radius: $border-radius-primary;

    text-align: left;

    pre {
        color: #fd8d25;
        white-space: pre-line;

        .brace {
            color: #999;
        }
    }

    &.collapsed {
        position: relative;

        padding: 4px 52px 4px 12px;

        & > pre {
            white-space: nowrap;
            overflow: hidden;
        }

        .btn-show-dialog {
            position: absolute;
            top: 50%;
            right: 16px;

            transform: translateY(-50%);
        }
    }
}
</style>